import { createAction } from '@reduxjs/toolkit';
import { AppShape } from './model';
import { ServiceFavorite } from '../../../general/api';

export const main = createAction<void, 'home/app/main'>('home/app/main');

export const setLoading = createAction<{ loading: AppShape['loading'] }, 'home/app/setLoading'>(
  'home/app/setLoading',
);

export const servicesInfoLoaded = createAction<
  { servicesInfo: AppShape['servicesInfo'] },
  'home/app/servicesInfoLoaded'
>('home/app/servicesInfoLoaded');

export const activeTabChanged = createAction<
  { activeTab: AppShape['activeTab'] },
  'home/app/activeTabChanged'
>('home/app/activeTabChanged');

export const favoriteClicked = createAction<
  { key: ServiceFavorite['key']; enabled: boolean },
  'home/app/favoriteClicked'
>('home/app/favoriteClicked');

export const setFavoriteInProgress = createAction<
  { key: ServiceFavorite['key']; enabled: boolean | undefined },
  'home/app/setFavoriteInProgress'
>('home/app/setFavoriteInProgress');

export const favoriteToggled = createAction<
  | { key: ServiceFavorite['key']; enabled: false }
  | { key: ServiceFavorite['key']; enabled: true; favorite: ServiceFavorite },
  'home/app/favoriteToggled'
>('home/app/favoriteToggled');
