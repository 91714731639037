// eslint-disable-next-line import/named
import { Selector } from 'reselect';
import { combineReducers } from 'redux';
import { appReducer } from './modules/app/reducer';

export const reducer = combineReducers({
  app: appReducer,
});

export type RootState = ReturnType<typeof reducer>;

export type RootSelector<T> = Selector<RootState, T>;
