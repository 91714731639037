type WindowWithGlobalVars = {
  AAHeader?: { render: (...args: any) => void };
};

export const initHeader = () => {
  const container = document.createElement('div');
  document.body.prepend(container);

  const script = document.createElement('script');
  script.src = `${import.meta.env.VITE_UNIVERSAL_STATIC_URL}/aa-header/aa-header.js`;
  script.onload = () => {
    (window as WindowWithGlobalVars).AAHeader?.render({
      container,
      // service: { key },
      addFonts: true,
      addAnt: false,
      title: 'All Solutions',
    });
  };
  document.head.appendChild(script);
};
