import { ServiceFavorite, ServiceGroup, ServiceItem, ServicesInfo } from '../../../general/api';

export const TAB_FAVORITES = 'favorites';
export const TAB_FAVORITES_DIVIDER = 'favorites_divider';
export const TAB_ALL_SOLUTIONS = 'all_solutions';

export type AppShape = {
  servicesInfo: ServicesInfo | undefined;
  loading: boolean;
  activeTab: string;

  // Временный набор карточек, чтобы на вкладке My favorites можно было снимать с них избранность,
  // и они при этом визуально не исчезали, пока юзер не перейдет на другую вкладку.
  tempFavorites: ServiceFavorite[] | undefined;

  // Если в favoritesNewState есть ключ какого-либо favorite, то значит что в данный момент этот favorite в обработке,
  // т.е. происходит запрос на бэк. Заодно храним новое состояние, чтобы его сразу отобразить.
  favoritesNewState: Partial<Record<ServiceFavorite['key'], boolean>>;
};

export type ServiceCard = { group: ServiceGroup; item: ServiceItem };

export const tabGroupId = {
  tab: (groupId: ServiceGroup['id']) => `group_${groupId}`,
  groupId: (tab: string): ServiceGroup['id'] | undefined => {
    if (!tab.startsWith('group_')) {
      return;
    }
    const stringId = tab.slice('group_'.length);
    const id = Number(stringId);
    return id;
  },
};

export const addressBarTab = {
  get: () => {
    const searchParams = new URL(window.location.href).searchParams;
    const param = searchParams.get('tab') ?? TAB_ALL_SOLUTIONS;
    return param;
  },
  set: (tab: string) => {
    const url = new URL(window.location.href);
    if (tab === TAB_ALL_SOLUTIONS) {
      url.searchParams.delete('tab');
    } else {
      url.searchParams.set('tab', tab);
    }
    window.history.replaceState(window.history.state, document.title, url.href);
  },
};
