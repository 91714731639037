import { useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  getActiveTab,
  getTempFavoriteCards,
  getLoading,
  getVisibleGroups,
  getIsFavoritesTabVisible,
} from '../selectors';
import '../styles.scss';
import { Tabs, Input, Spin } from '@myua/kit';
import { SearchNormal1 } from '@myua/icons/dist/linear16';
import * as appActions from '../actions';
import { TAB_ALL_SOLUTIONS, TAB_FAVORITES, TAB_FAVORITES_DIVIDER, tabGroupId } from '../model';
import { Cards } from './Cards';

export const App = () => {
  const dispatch = useDispatch();
  const loading = useSelector(getLoading);
  const groups = useSelector(getVisibleGroups);
  const favoriteCards = useSelector(getTempFavoriteCards);
  const isFavoritesTabVisible = useSelector(getIsFavoritesTabVisible);
  const activeTab = useSelector(getActiveTab);

  const [searchText, setSearchText] = useState('');

  const filtered = useMemo(() => {
    const text = searchText.toLowerCase().trim();
    return groups
      .flatMap((group) => group.items.map((item) => ({ item, group })))
      .filter(({ item }) => item.name.toLowerCase().includes(text));
  }, [groups, searchText]);

  return (
    <div className="home-app">
      <h1 className="home-app__header">Explore AdsAdvisor Gaming Solutions</h1>
      <div className="home-app__tabs-container">
        {loading && <Spin spinning={true} className="home-app-spin" />}
        <Tabs
          destroyInactiveTabPane
          className={`home-app-tabs ${isFavoritesTabVisible ? 'home-app-tabs_favorites' : ''}`}
          activeKey={activeTab}
          onChange={(activeKey) => {
            setSearchText('');
            dispatch(appActions.activeTabChanged({ activeTab: activeKey }));
          }}
          items={[
            ...(isFavoritesTabVisible
              ? [
                  {
                    label: 'My favorites',
                    key: TAB_FAVORITES,
                    children: <Cards cards={favoriteCards} />,
                  },
                  {
                    label: '',
                    key: TAB_FAVORITES_DIVIDER,
                    disabled: true,
                  },
                ]
              : []),
            {
              label: 'All Solutions',
              key: TAB_ALL_SOLUTIONS,
              children: (
                <>
                  <div className="home-app-search">
                    <Input
                      allowClear
                      placeholder="Search tools and services"
                      suffix={<SearchNormal1 />}
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                    />
                  </div>
                  <Cards cards={filtered} />
                </>
              ),
            },
            ...groups.map((group) => ({
              label: group.name,
              key: tabGroupId.tab(group.id),
              children: <Cards cards={group.items.map((item) => ({ group, item }))} />,
            })),
          ]}
        />
      </div>
    </div>
  );
};
