import { FC } from 'react';
import { ServiceCard } from '../model';
import { Card } from './Card';

export const Cards: FC<{ cards: ServiceCard[] }> = ({ cards }) => {
  return (
    <div className="home-service-cards">
      <div className="home-service-cards__grid">
        {cards.map((card) => (
          <Card key={card.item.key} card={card} />
        ))}
      </div>
    </div>
  );
};
