import { FC } from 'react';
import { ServiceCard } from '../model';
import { useDispatch, useSelector } from 'react-redux';
import { getFavoriteKeys, getFavoritesNewState } from '../selectors';
import * as appActions from '../actions';
import { Star as StarFilled } from '@myua/icons/dist/bulk16';
import { Star1 as StarEmpty } from '@myua/icons/dist/linear16';
import { Export2 } from '@myua/icons/dist/linear14';

export const Card: FC<{ card: ServiceCard }> = ({ card }) => {
  const dispatch = useDispatch();
  const { item, group } = card;
  const favoritesNewState = useSelector(getFavoritesNewState);
  const favoriteNewState = favoritesNewState[item.key];
  const favoriteKeys = useSelector(getFavoriteKeys);
  const isFavorite = favoriteNewState ?? favoriteKeys[item.key] ?? false;

  return (
    <a className="home-service-card" href={item.url}>
      <div
        className="home-service-card__favorite"
        onClick={(e) => {
          e.preventDefault();
          if (favoriteNewState !== undefined) {
            return;
          }
          dispatch(appActions.favoriteClicked({ key: item.key, enabled: !isFavorite }));
        }}
      >
        {isFavorite ? (
          <StarFilled className="home-service-card__favorite-icon home-service-card__favorite-icon_filled" />
        ) : (
          <StarEmpty className="home-service-card__favorite-icon" />
        )}
      </div>
      <div className="home-service-card__top">
        <div className="home-service-card__icon">{item.icon && <img src={item.icon} alt="" />}</div>
        <div className="home-service-card__group">{group.name}</div>
      </div>
      <div className="home-service-card__title">{item.name}</div>
      <div className="home-service-card__short-descr">{item.short_description}</div>
      <div className="home-service-card__open">
        <Export2 className="home-service-card__open-icon" />
        Open
      </div>
    </a>
  );
};
