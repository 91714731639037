import { createReducer } from '@reduxjs/toolkit';
import * as appActions from './actions';
import { AppShape, TAB_ALL_SOLUTIONS, TAB_FAVORITES } from './model';

const initial: AppShape = {
  servicesInfo: undefined,
  loading: false,
  activeTab: TAB_ALL_SOLUTIONS,
  tempFavorites: undefined,
  favoritesNewState: {},
};

export const appReducer = createReducer<AppShape>(initial, (builder) =>
  builder
    .addCase(appActions.servicesInfoLoaded, (s, a) => {
      s.servicesInfo = a.payload.servicesInfo;
    })
    .addCase(appActions.setLoading, (s, a) => {
      s.loading = a.payload.loading;
    })
    .addCase(appActions.activeTabChanged, (s, a) => {
      s.activeTab = a.payload.activeTab;
      if (a.payload.activeTab === TAB_FAVORITES) {
        s.tempFavorites = s.servicesInfo?.favorites;
      } else {
        s.tempFavorites = undefined;
      }
    })
    .addCase(appActions.favoriteToggled, (s, a) => {
      if (!s.servicesInfo) {
        return;
      }
      s.servicesInfo.favorites = s.servicesInfo?.favorites.filter(
        ({ key }) => key !== a.payload.key,
      );
      if (a.payload.enabled) {
        s.servicesInfo.favorites.push(a.payload.favorite);
      }
    })
    .addCase(appActions.setFavoriteInProgress, (s, a) => {
      if (a.payload.enabled === undefined) {
        delete s.favoritesNewState[a.payload.key];
      } else {
        s.favoritesNewState[a.payload.key] = a.payload.enabled;
      }
    }),
);
