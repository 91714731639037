import { configureStore } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';
import { reducer } from './reducer';
import { listenerMiddleware } from './listenerMiddleware';
import { main } from './modules/app/actions';

export const store = configureStore({
  reducer,

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .prepend(listenerMiddleware.middleware)
      .concat(import.meta.env.VITE_SHOW_LOGGER ? [createLogger()] : []),
});

store.dispatch(main());

export default store;
