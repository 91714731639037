import { createRoot } from 'react-dom/client';
import { App } from './modules/app/components/App';
import '@myua/kit/dist/aa-kit.css';
import { Provider } from 'react-redux';
import { store } from './store';
import { initHeader } from '../general/initHeader';

initHeader();

const root = document.getElementById('root');
if (root) {
  createRoot(root).render(
    <Provider store={store}>
      <App />
    </Provider>,
  );
}
