import { createSelector } from 'reselect';
import { RootSelector, RootState } from '../../reducer';
import { AppShape, ServiceCard } from './model';
import { ServiceFavorite, ServiceGroup } from '../../../general/api';

export const getRoot: RootSelector<AppShape> = createSelector(
  (state: RootState) => state,
  (state) => state.app,
);

export const getServicesInfo: RootSelector<AppShape['servicesInfo']> = createSelector(
  getRoot,
  (root) => root.servicesInfo,
);

export const getVisibleGroups: RootSelector<ServiceGroup[]> = createSelector(
  getServicesInfo,
  (servicesInfo) => (servicesInfo?.groups ?? []).filter(({ items }) => items.length),
);

export const getTempFavorites: RootSelector<AppShape['tempFavorites']> = createSelector(
  getRoot,
  (root) => root.tempFavorites,
);

export const getTempFavoriteCards: RootSelector<ServiceCard[]> = createSelector(
  [getServicesInfo, getTempFavorites],
  (servicesInfo, tempFavorites) => {
    const cards = (servicesInfo?.groups ?? []).flatMap((group) =>
      group.items.map((item) => ({ group, item })),
    );
    const result = cards.filter((card) => tempFavorites?.find(({ key }) => key === card.item.key));
    return result;
  },
);

export const getIsFavoritesTabVisible: RootSelector<boolean> = createSelector(
  [getTempFavoriteCards, getServicesInfo],
  (tempFavoriteCards, servicesInfo) => {
    if (tempFavoriteCards.length) {
      return true;
    }
    // Даже если с бэка пришел список favorites, то надо проверить, доступны ли соответствующие сервисы.
    // Могут быть случаи, когда сервис добавили в избранное, а потом отключили доступ к сервису.
    const keys = servicesInfo?.groups.flatMap((group) => group.items.map(({ key }) => key));
    const favoritesAvailable =
      servicesInfo?.favorites.some((favorite) => keys?.find((key) => favorite.key === key)) ??
      false;
    return favoritesAvailable;
  },
);

export const getFavoriteKeys: RootSelector<Partial<Record<ServiceFavorite['key'], true>>> =
  createSelector(getServicesInfo, (servicesInfo) => {
    const result: Partial<Record<ServiceFavorite['key'], true>> = {};
    servicesInfo?.favorites.forEach(({ key }) => {
      result[key] = true;
    });
    return result;
  });

export const getFavoritesNewState: RootSelector<AppShape['favoritesNewState']> = createSelector(
  getRoot,
  (root) => root.favoritesNewState,
);

export const getLoading: RootSelector<AppShape['loading']> = createSelector(
  getRoot,
  (root) => root.loading,
);

export const getActiveTab: RootSelector<AppShape['activeTab']> = createSelector(
  getRoot,
  (root) => root.activeTab,
);
