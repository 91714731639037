import { takeOnce, takeEvery, takeLatestById } from '../../listenerMiddleware';
import * as appActions from './actions';
import { getActiveTab, getIsFavoritesTabVisible, getVisibleGroups } from './selectors';
import { addressBarTab, TAB_ALL_SOLUTIONS, TAB_FAVORITES, tabGroupId } from './model';
import * as api from '../../../general/api';
import { message } from '@myua/kit';

export const appListeners = () => {
  loadServices();
  initActiveTab();
  activeTabChanged();
  favoriteClicked();
};

function loadServices() {
  takeOnce(appActions.main, async (action, { dispatch }) => {
    dispatch(appActions.setLoading({ loading: true }));
    const result = await api.loadServicesInfo();
    dispatch(appActions.setLoading({ loading: false }));

    if (result.success) {
      dispatch(appActions.servicesInfoLoaded({ servicesInfo: result.data.response }));
    } else {
      void message.error('Services information has not been loaded');
    }
  });
}

function initActiveTab() {
  takeOnce(appActions.servicesInfoLoaded, (action, { getState, dispatch }) => {
    const activeTab = addressBarTab.get();

    dispatch(appActions.activeTabChanged({ activeTab }));

    const isFavoritesTabVisible = getIsFavoritesTabVisible(getState());

    if (activeTab === TAB_ALL_SOLUTIONS) {
      if (isFavoritesTabVisible) {
        dispatch(appActions.activeTabChanged({ activeTab: TAB_FAVORITES }));
      }
      return;
    }

    if (activeTab === TAB_FAVORITES) {
      if (!isFavoritesTabVisible) {
        dispatch(appActions.activeTabChanged({ activeTab: TAB_ALL_SOLUTIONS }));
      }
      return;
    }

    const groupId = tabGroupId.groupId(activeTab);
    if (groupId === undefined) {
      return;
    }

    const groups = getVisibleGroups(getState());
    const group = groups.find(({ id }) => id === groupId);
    if (!group) {
      dispatch(appActions.activeTabChanged({ activeTab: TAB_ALL_SOLUTIONS }));
    }
  });
}

function activeTabChanged() {
  takeEvery(appActions.activeTabChanged, (action, { getState }) => {
    const activeTab = getActiveTab(getState());
    const tab = addressBarTab.get();
    if (activeTab === tab) {
      return;
    }
    addressBarTab.set(activeTab);
  });
}

function favoriteClicked() {
  takeLatestById(
    appActions.favoriteClicked,
    ({ payload }) => payload.key,
    async (action, { dispatch }, { signal }) => {
      const { key, enabled } = action.payload;

      dispatch(appActions.setFavoriteInProgress({ key, enabled }));

      if (enabled) {
        const result = await api.createFavorite(key);
        if (signal.aborted) {
          return;
        }
        if (result.success) {
          const favorite = result.data.response;
          dispatch(appActions.favoriteToggled({ key: favorite.key, enabled, favorite }));
        } else {
          void message.error('Favorite has not been saved');
        }
      } else {
        const result = await api.deleteFavorite(key);
        if (signal.aborted) {
          return;
        }
        if (result.success) {
          dispatch(appActions.favoriteToggled({ key, enabled }));
        } else {
          void message.error('Favorite has not been deleted');
        }
      }

      dispatch(appActions.setFavoriteInProgress({ key, enabled: undefined }));
    },
  );
}
